import $ from "jquery";

import "./bootstrap";

import "flowbite";

import Alpine from "alpinejs";

import Inputmask from "inputmask";

window.Alpine = Alpine;

Alpine.start();

// filter users on admin dashboard
document.addEventListener("DOMContentLoaded", function () {
    // Check if the current page is /system/users
    if (window.location.pathname === "/system/users") {
        const roleFilters = document.querySelectorAll(".roleFilter");

        if (roleFilters.length > 0) {
            // Check if there are any role filters
            roleFilters.forEach(function (filter) {
                filter.addEventListener("change", function () {
                    const selectedRole = this.value.toLowerCase();
                    const userCards = document.querySelectorAll(".user-card");

                    userCards.forEach((card) => {
                        const userRole = card
                            .getAttribute("data-role")
                            .toLowerCase();

                        // Show or hide the card based on the selected role
                        if (
                            selectedRole === "" ||
                            userRole.includes(selectedRole)
                        ) {
                            card.style.display = "block";
                        } else {
                            card.style.display = "none";
                        }
                    });
                });
            });
        }
    }
});

// Function to hide the alert after 3 seconds
document.addEventListener("DOMContentLoaded", function () {
    setTimeout(function () {
        var errorAlert = document.getElementById("error-alert");
        var successAlert = document.getElementById("success-alert");

        if (errorAlert) {
            errorAlert.style.display = "none";
        }
        if (successAlert) {
            successAlert.style.display = "none";
        }
    }, 3000);
});

document.addEventListener("DOMContentLoaded", function () {
    // Select all forms with the class 'delete-form'
    const deleteForms = document.querySelectorAll(".delete-form");

    deleteForms.forEach(function (form) {
        // Add a submit event listener to each form
        form.addEventListener("submit", function (event) {
            // Show confirmation dialog
            const confirmation = confirm(
                "Are you sure you want to delete this document? This action cannot be undone."
            );

            // If the user cancels, prevent form submission
            if (!confirmation) {
                event.preventDefault();
                document.getElementById("hiddenLoadingButton").click();
            }
        });
    });

    // Add a click event listener for the submitInspection button
    const submitInspectionButton = document.getElementById("submitInspection");

    if (submitInspectionButton) {
        submitInspectionButton.addEventListener("click", function (event) {
            // Show confirmation dialog for the inspection submission
            const confirmation = confirm(
                "Are you sure you want to submit this inspection?"
            );

            // If the user cancels, prevent the default action
            if (!confirmation) {
                event.preventDefault();
            }
        });
    }
});

document.addEventListener("DOMContentLoaded", function () {
    // Get all claim-container elements
    const claimContainers = document.querySelectorAll(".claim-container");

    // Loop through each container
    claimContainers.forEach(function (container) {
        const claimNumberElement = container.querySelector(".claimNumber"); // The claim number span
        const copyIcon = container.querySelector(".copyIcon"); // The copy icon

        // Show the copy icon when hovering over the container
        claimNumberElement.addEventListener("mouseenter", function () {
            copyIcon.classList.remove("hidden");
        });

        // Hide the copy icon when not hovering over the container
        claimNumberElement.addEventListener("mouseleave", function () {
            copyIcon.classList.add("hidden");
        });

        // Copy the claim number to clipboard on clicking the entire container
        claimNumberElement.addEventListener("click", function () {
            const claimNumberText = claimNumberElement.textContent.trim();
            navigator.clipboard
                .writeText(claimNumberText)
                .then(function () {
                    // Provide some feedback by changing the icon style temporarily
                    copyIcon.classList.remove(
                        "text-blue-700",
                        "bg-blue-50",
                        "ring-blue-600/20"
                    );
                    copyIcon.classList.add(
                        "text-green-700",
                        "bg-green-50",
                        "ring-green-600/20"
                    );
                    copyIcon.textContent = "Copied"; // Change icon text

                    setTimeout(function () {
                        // Revert back to the original style and text after 2 seconds
                        copyIcon.classList.remove(
                            "text-green-700",
                            "bg-green-50",
                            "ring-green-600/20"
                        );
                        copyIcon.classList.add(
                            "text-blue-700",
                            "bg-blue-50",
                            "ring-blue-600/20"
                        );
                        copyIcon.textContent = "Copy"; // Revert text
                    }, 2000);
                })
                .catch(function (err) {
                    console.error("Could not copy text: ", err);
                });
        });
    });
});

document.addEventListener("DOMContentLoaded", function () {
    // Check if the current page is /inspections
    if (document.querySelector("form")) {
        // Phone Mask (US Format)
        const phoneField = document.querySelector("input[type='tel']");
        const phoneFeedback = document.getElementById("phone-feedback");
        if (phoneField) {
            const phoneMask = new Inputmask("(999) 999-9999");
            phoneMask.mask(phoneField);

            // Listen for input changes
            phoneField.addEventListener("input", function () {
                if (phoneField.inputmask.isComplete()) {
                    phoneFeedback.textContent = "Looks good!";
                    phoneFeedback.classList.remove("hidden");
                } else {
                    phoneFeedback.classList.add("hidden");
                }
            });
        }

        // Email Mask (Basic Email Structure)
        const emailField = document.querySelector("input[type='email']");
        const emailFeedback = document.getElementById("email-feedback");

        emailField.addEventListener("input", function () {
            // Regular expression for validating an email address
            const emailPattern =
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            // Check if the value matches the email pattern
            if (emailPattern.test(emailField.value)) {
                emailFeedback.textContent = "Looks good!";
                emailFeedback.classList.remove("hidden");
            } else {
                emailFeedback.textContent = ""; // Clear the text if it doesn't match
                emailFeedback.classList.add("hidden");
            }
        });

        // Text Mask (Alphabets Only)
        const textFields = document.querySelectorAll("input[type='text']");
        textFields.forEach((field) => {
            const textFeedback = document.createElement("p");
            textFeedback.classList.add("text-green-500", "hidden");
            textFeedback.textContent = "Looks good!";
            field.parentNode.appendChild(textFeedback);

            field.addEventListener("input", function () {
                if (field.value.trim() !== "") {
                    // Check if field is not empty
                    textFeedback.classList.remove("hidden");
                } else {
                    textFeedback.classList.add("hidden");
                }
            });
        });

        // Dropdowns
        const selectFields = document.querySelectorAll("select[required]");
        selectFields.forEach((field) => {
            const textFeedback = document.createElement("p");
            textFeedback.classList.add("text-green-500", "mt-2", "hidden");
            textFeedback.textContent = "Looks good!";
            field.parentNode.appendChild(textFeedback);

            field.addEventListener("input", function () {
                if (field.value.trim() !== "") {
                    // Check if field is not empty
                    textFeedback.classList.remove("hidden");
                } else {
                    textFeedback.classList.add("hidden");
                }
            });
        });
    }
});

document.addEventListener("DOMContentLoaded", function () {
    if (
        document.querySelector("form") &&
        window.location.pathname === "/inspections/create"
    ) {
        const checkboxYes = document.getElementById("is_re_inspection_yes");
        const checkboxNo = document.getElementById("is_re_inspection_no");
        const sterlingYes = document.getElementById(
            "original_was_sterling_yes"
        );
        const sterlingNo = document.getElementById("original_was_sterling_no");
        const conditionalFields = document.getElementById("conditional-fields");
        const sterlingFieldsNo = document.getElementById(
            "sterling-conditional-fields-no"
        );
        const sterlingFieldsYes = document.getElementById(
            "sterling-conditional-fields-yes"
        );

        function toggleReInspectionFields() {
            // Show fields if "Yes" is selected, hide if "No"
            if (checkboxYes.checked) {
                sterlingYes.setAttribute("required", "required");
                sterlingNo.setAttribute("required", "required");
                conditionalFields.classList.remove("hidden");
            } else {
                sterlingYes.removeAttribute("required", "required");
                sterlingNo.removeAttribute("required", "required");
                conditionalFields.classList.add("hidden");
            }
        }

        function toggleSterlingFields() {
            // Show fields if "Yes" is selected, hide if "No"
            if (sterlingYes.checked) {
                sterlingFieldsYes.classList.remove("hidden");
                sterlingFieldsNo.classList.add("hidden");
            } else {
                sterlingFieldsYes.classList.add("hidden");
                sterlingFieldsNo.classList.remove("hidden");
            }
        }

        checkboxYes.addEventListener("change", toggleReInspectionFields);
        checkboxNo.addEventListener("change", toggleReInspectionFields);
        sterlingYes.addEventListener("change", toggleSterlingFields);
        sterlingNo.addEventListener("change", toggleSterlingFields);
    }
});

document.addEventListener("DOMContentLoaded", function () {
    if (window.location.pathname === "/") {
        const travelYes = document.getElementById("travel_yes");
        const travelNo = document.getElementById("travel_no");
        const travelFields = document.getElementById("travel-fields");
        const travelDistanceField = document.getElementById("travel_distance");

        function toggleTravelField() {
            // Show fields if "Yes" is selected, hide if "No"
            if (travelYes.checked) {
                travelDistanceField.setAttribute("required", "required");
                travelFields.classList.remove("hidden");
            } else {
                travelDistanceField.removeAttribute("required", "required");
                travelFields.classList.add("hidden");
            }
        }

        travelYes.addEventListener("change", toggleTravelField);
        travelNo.addEventListener("change", toggleTravelField);
    }
});

document.addEventListener("DOMContentLoaded", function () {
    if (window.location.pathname === "/") {
        const steps = document.querySelectorAll(
            "#multi-step-form > div[id='step']"
        );
        const nextButtons = document.querySelectorAll(
            "#multi-step-form .next-btn"
        );
        const backButtons = document.querySelectorAll(
            "#multi-step-form .back-btn"
        );

        let currentStep = 0;

        function showStep(stepIndex) {
            steps.forEach((step, index) => {
                if (index === stepIndex) {
                    step.classList.remove("hidden");
                } else {
                    step.classList.add("hidden");
                }
            });
        }

        nextButtons.forEach((button) => {
            button.addEventListener("click", function (e) {
                e.preventDefault(); // Prevent the form from submitting

                if (currentStep < steps.length - 1) {
                    currentStep++;
                    showStep(currentStep);
                }
            });
        });

        backButtons.forEach((button) => {
            button.addEventListener("click", function (e) {
                e.preventDefault(); // Prevent form submission
                if (currentStep > 0) {
                    currentStep--;
                    showStep(currentStep);
                }
            });
        });

        // Initialize the first step as visible
        showStep(currentStep);
    }
});

document.addEventListener("submit", function (event) {
    // Show the popup
    // Trigger the hidden button (which can have its own click event)
    document.getElementById("hiddenLoadingButton").click();
});

document.addEventListener("DOMContentLoaded", function () {
    // Function to update file names when files are selected
    function updateFileName() {
        const input = document.getElementById("file");
        const fileNameDisplay = document.getElementById("file-name");
        const fileLabel = document.getElementById("file-label");

        if (input && fileNameDisplay && fileLabel) {
            input.addEventListener("change", function () {
                const fileCount = input.files.length;

                if (fileCount > 0) {
                    const fileNames = Array.from(input.files)
                        .map((file) => file.name)
                        .join(", ");
                    fileNameDisplay.textContent = fileNames;
                    fileLabel.textContent = `${fileCount} file(s)`;
                } else {
                    fileNameDisplay.textContent = "None Selected";
                    fileLabel.textContent = "Choose Files"; // Reset to default label text
                }
            });
        }
    }

    updateFileName();
});

document.addEventListener("DOMContentLoaded", function () {
    function toggleLadderAssistFields() {
        // Get the selected value of the service type dropdown
        const serviceType = document.getElementById("service_type").value;
        const ladderConditional = document.getElementById(
            "ladder-assist-conditional"
        );
        const iweConditional = document.getElementById("iwe-conditional");
        const interiorConditional = document.getElementById(
            "interior-only-conditional"
        );

        ladderConditional.classList.add("hidden");
        iweConditional.classList.add("hidden");
        interiorConditional.classList.add("hidden");

        // Toggle visibility based on the selected value
        if (serviceType === "Ladder Assist") {
            ladderConditional.classList.remove("hidden");
        } else if (serviceType === "Inspection with Estimate") {
            iweConditional.classList.remove("hidden");
        } else if (serviceType === "Interior Only Inspection") {
            interiorConditional.classList.remove("hidden");
        }
    }

    // Add event listener to call the function when the dropdown selection changes
    document
        .getElementById("service_type")
        .addEventListener("change", toggleLadderAssistFields);
});

document.addEventListener("DOMContentLoaded", function () {
    function toggleAncillaryServices() {
        const serviceType = document.getElementById("service_type").value;

        // Get the checkboxes by their IDs
        const ancOptions = document.getElementById("ancillary-options");
        const anc1 = document.getElementById("anc-1");
        const ancCheckbox1 = document.getElementById("anc-cb-1");
        const anc2 = document.getElementById("anc-2");
        const ancCheckbox2 = document.getElementById("anc-cb-2");
        const anc3 = document.getElementById("anc-3");
        const ancCheckbox3 = document.getElementById("anc-cb-3");
        const anc4 = document.getElementById("anc-4");
        const ancCheckbox4 = document.getElementById("anc-cb-4");
        const anc5 = document.getElementById("anc-5");
        const ancCheckbox5 = document.getElementById("anc-cb-5");
        const anc6 = document.getElementById("anc-6");
        const ancCheckbox6 = document.getElementById("anc-cb-6");

        // Reset all checkboxes initially
        ancOptions.classList.add("hidden");
        anc1.classList.add("hidden");
        anc2.classList.add("hidden");
        anc3.classList.add("hidden");
        anc4.classList.add("hidden");
        anc5.classList.add("hidden");
        anc6.classList.add("hidden");
        ancCheckbox1.checked = false;
        ancCheckbox2.checked = false;
        ancCheckbox3.checked = false;
        ancCheckbox4.checked = false;
        ancCheckbox5.checked = false;
        ancCheckbox6.checked = false;

        // Dynamically check certain checkboxes based on the selected service type
        if (serviceType === "Ladder Assist") {
            ancOptions.classList.remove("hidden");
            anc2.classList.remove("hidden");
            anc3.classList.remove("hidden");
            anc4.classList.remove("hidden");
            anc6.classList.remove("hidden");
        } else if (serviceType === "Direct/Solo Inspection") {
            ancOptions.classList.remove("hidden");
            anc1.classList.remove("hidden");
            anc2.classList.remove("hidden");
            anc3.classList.remove("hidden");
            anc4.classList.remove("hidden");
            anc5.classList.remove("hidden");
            anc6.classList.remove("hidden");
        } else if (
            serviceType === "Emergency Services (Tarp/Boardup- NO inspection)"
        ) {
            ancOptions.classList.remove("hidden");
            anc3.classList.remove("hidden");
            anc4.classList.remove("hidden");
            anc6.classList.remove("hidden");
        } else if (serviceType === "Inspection with Estimate") {
            ancOptions.classList.remove("hidden");
            anc1.classList.remove("hidden");
            anc2.classList.remove("hidden");
            anc3.classList.remove("hidden");
            anc4.classList.remove("hidden");
            anc5.classList.remove("hidden");
            anc6.classList.remove("hidden");
        } else if (serviceType === "Interior Only Inspection") {
            ancOptions.classList.remove("hidden");
            anc2.classList.remove("hidden");
            anc5.classList.remove("hidden");
        } else if (serviceType === "ITEL Sample Only") {
            ancOptions.classList.remove("hidden");
            anc2.classList.remove("hidden");
            anc3.classList.remove("hidden");
            anc4.classList.remove("hidden");
            anc5.classList.remove("hidden");
        }
    }

    // Add event listener for changes in service_type dropdown
    document
        .getElementById("service_type")
        .addEventListener("change", toggleAncillaryServices);
});

// Toggle the my services drawer visibility
if (window.location.pathname === "/inspections") {
    $("#myServicesToggle").click(function (event) {
        event.preventDefault();
        $("#myServicesDrawer").slideToggle(500); // Slide down/up with 500ms animation
    });
    $(".searchFormButton").click(function (event) {
        $("#searchForm").slideToggle(500);
    });
}

// Details toggle
if (window.location.pathname.match(/^\/inspections\/\d+$/)) {
    $("#detailsToggle").click(function (event) {
        event.preventDefault();
        $("#detailsDrawer").slideToggle(500);
        $("#arrowIcon").toggleClass("rotate-180");
        $("html, body").animate(
            {
                scrollTop: $("#detailsDrawer").offset().top - 120,
            },
            500
        );
    });
}

$("#profileDropdown").click(function (event) {
    $("#profileArrowIcon").toggleClass("rotate-180");
});

document.addEventListener("DOMContentLoaded", function () {
    // Find all dropzones on the page
    const dropzones = document.querySelectorAll("[id^='dropzone-label']");

    if (dropzones.length > 0) {
        dropzones.forEach((dropzoneLabel) => {
            const userId = dropzoneLabel
                .getAttribute("for")
                .replace("dropzone-file", "");
            const dropzoneInput = document.getElementById(
                `dropzone-file${userId}`
            );
            const dropzoneDynamicText = dropzoneLabel.querySelector(
                "#dropzone-dynamictext"
            );

            // Function to update the text with file names
            const updateDynamicText = (files) => {
                if (!dropzoneDynamicText) {
                    console.error("Dynamic text element not found");
                    return;
                }

                try {
                    if (files && files.length > 0) {
                        let fileNames = Array.from(files)
                            .map((file) => file.name)
                            .join(", ");
                        dropzoneDynamicText.innerHTML = `<p class="text-sm text-gray-500 dark:text-gray-400 font-semibold">Uploaded files:</p><p class="text-xs text-gray-500 dark:text-gray-400">${fileNames}</p>`;
                    } else {
                        dropzoneDynamicText.innerHTML = `<p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p><p class="text-xs text-gray-500 dark:text-gray-400">PDF, Image, Excel, Zip, or ESX</p>`;
                    }
                } catch (error) {
                    console.error("Error updating dynamic text:", error);
                }
            };

            // Prevent default behaviors for drag and drop
            const preventDefaults = (e) => {
                e.preventDefault();
                e.stopPropagation();
            };

            // Highlight the drop area
            const highlight = () => {
                dropzoneLabel.classList.remove("bg-gray-50", "border-gray-300");
                dropzoneLabel.classList.add("bg-gray-100", "border-blue-500");
            };

            const unhighlight = () => {
                dropzoneLabel.classList.add("bg-gray-50", "border-gray-300");
                dropzoneLabel.classList.remove(
                    "bg-gray-100",
                    "border-blue-500"
                );
            };

            // Handle the drop event
            const handleDrop = (e) => {
                const files = e.dataTransfer.files; // Get the dropped files
                if (files.length) {
                    dropzoneInput.files = files; // Update the hidden input
                    updateDynamicText(files); // Update text with file names
                }
                unhighlight();
            };

            // Add event listeners for drag and drop
            dropzoneLabel.addEventListener("dragenter", highlight);
            dropzoneLabel.addEventListener("dragover", preventDefaults);
            dropzoneLabel.addEventListener("drop", (e) => {
                preventDefaults(e);
                handleDrop(e);
            });

            // Update the text when files are selected via input
            dropzoneInput.addEventListener("change", (e) => {
                updateDynamicText(e.target.files);
                unhighlight();
            });
        });
    }
});

document.addEventListener("DOMContentLoaded", function () {
    const form = document.getElementById("jobSubmissionRequest"); // Replace with your form ID
    const checkboxes = document.querySelectorAll(
        'input[name="job_data[services][]"]'
    );
    const errorMessage = document.createElement("p");
    errorMessage.classList.add("text-red-500", "mt-2");
    errorMessage.textContent =
        "At least one ancillary service must be selected.";
    const ancillaryOptions = document.getElementById("ancillary-options"); // Replace with your checkboxes container ID
    let isErrorShown = false;

    form.addEventListener("submit", function (event) {
        let isChecked = false;

        // Loop through checkboxes to see if at least one is checked
        checkboxes.forEach(function (checkbox) {
            if (checkbox.checked) {
                isChecked = true;
            }
        });

        // If none are checked, prevent form submission and show error
        if (!isChecked) {
            event.preventDefault(); // Prevent form from submitting
            window.alert(
                "Please select at least one ancillary service before submitting the form."
            );
            if (!isErrorShown) {
                ancillaryOptions.appendChild(errorMessage); // Show error message
                isErrorShown = true;
            }
            ancillaryOptions.scrollIntoView({
                behavior: "smooth",
                block: "center",
            }); // Scroll to the checkboxes
            document.getElementById("hiddenLoadingButton").click();
        } else {
            if (isErrorShown) {
                errorMessage.remove(); // Remove error message if checkboxes are valid
                isErrorShown = false;
            }
        }
    });
});

document.addEventListener("DOMContentLoaded", function () {
    window.submitFormWithValue = function (value) {
        try {
            // Get the status filter select element
            const statusFilter = document.getElementById("status-filter");

            if (!statusFilter) {
                console.error("Status filter element not found");
                return;
            }

            // Find and select the option with matching value
            const options = statusFilter.options;
            let optionFound = false;

            // First remove 'selected' attribute from all options
            Array.from(options).forEach((option) => {
                option.removeAttribute("selected");
            });

            // Find and select the matching option
            for (let i = 0; i < options.length; i++) {
                if (options[i].value === value) {
                    options[i].setAttribute("selected", "selected");
                    statusFilter.selectedIndex = i;
                    optionFound = true;
                    break;
                }
            }

            if (!optionFound) {
                console.warn(
                    `Option with value "${value}" not found in status filter`
                );
                return;
            }

            // Get the form element
            const filterForm = document.getElementById("filter-form");

            if (!filterForm) {
                console.error("Filter form not found");
                return;
            }

            // Trigger change event on select element
            const changeEvent = new Event("change", { bubbles: true });
            statusFilter.dispatchEvent(changeEvent);
        } catch (error) {
            console.error("Error in submitFormWithValue:", error);
        }
    };
});

document.addEventListener("DOMContentLoaded", () => {
    const themeToggler = document.getElementById("theme-toggler");
    const themeTogglerMobile = document.getElementById("theme-toggler-mobile");
    const currentTheme = localStorage.getItem("theme");

    if (currentTheme === "dark") {
        document.documentElement.classList.add("dark");
    }

    themeToggler.addEventListener("click", () => {
        document.documentElement.classList.toggle("dark");
        if (document.documentElement.classList.contains("dark")) {
            localStorage.setItem("theme", "dark");
        } else {
            localStorage.setItem("theme", "light");
        }
    });

    themeTogglerMobile.addEventListener("click", () => {
        document.documentElement.classList.toggle("dark");
        if (document.documentElement.classList.contains("dark")) {
            localStorage.setItem("theme", "dark");
        } else {
            localStorage.setItem("theme", "light");
        }
    });
});

// Check and apply sidebar state on page load
document.addEventListener("DOMContentLoaded", () => {
    function toggleSidebar() {
        const sidebar = document.getElementById("sidebar");
        const mainBucket = document.getElementById("mainBucket");

        // Toggle sidebar visibility classes
        sidebar.classList.toggle("hidden");
        mainBucket.classList.toggle("sm:pl-4");
        mainBucket.classList.toggle("sm:ml-64");

        // Save sidebar state to localStorage
        const isSidebarHidden = sidebar.classList.contains("hidden");
        localStorage.setItem("sidebarHidden", isSidebarHidden);
    }

    // Attach toggleSidebar to the global scope if needed
    window.toggleSidebar = toggleSidebar;

    // Restore sidebar state on page load
    const sidebarHidden = localStorage.getItem("sidebarHidden") === "true";
    const sidebar = document.getElementById("sidebar");
    const mainBucket = document.getElementById("mainBucket");

    if (sidebarHidden) {
        sidebar.classList.add("hidden");
        mainBucket.classList.add("sm:pl-4");
        mainBucket.classList.remove("sm:ml-64");
    } else {
        sidebar.classList.remove("hidden");
        mainBucket.classList.remove("sm:pl-4");
        mainBucket.classList.add("sm:ml-64");
    }
});
